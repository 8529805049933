import React from "react"

import Seo from "../components/functional/seo/Seo"
import Layout from "../components/layout/Layout"

import * as styles from "../styles/pages/404"
import { graphql, useStaticQuery } from "gatsby"
import Button from "../components/ui/button/Button"
import HorizontalLine from "../components/ui/horizontal-line/HorizontalLine"
import LinearPattern from "../components/ui/linear-pattern/LinearPattern"
import { GatsbyImage } from "gatsby-plugin-image"

import AnalyticsPageError from "../components/functional/analytics/analytics-page-error/AnalyticsPageError"
import { H1 } from "../components/ui/headers/Headers"

const NotFoundPage = () => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    page404 {
                        acf {
                            page404Image {
                                ...gatsbyFluidImage
                            }
                            page404Title
                            page404Subtitle
                            page404Link {
                                target
                                title
                                url
                            }
                        }
                    }
                }
            }
        `
    )

    if (data.wp.page404.acf) {
        const {
            page404Title,
            page404Subtitle,
            page404Link: { target, title, url },
            page404Image: {
                localFile: {
                    childImageSharp: { gatsbyImageData: page404Image },
                },
            },
        } = data.wp.page404.acf

        return (
            <>
                <Seo lang="en" description="404" title="404: Not found" />
                <AnalyticsPageError error={404} />
                <Layout>
                    <div className={`t-Dark ${styles.Page404}`}>
                        <div
                            className={`
                                l-Inner row
                                ${styles.Page404__Content}`}
                        >
                            <div className="small-12 medium-5 xlarge-4">
                                <H1
                                    className="h1--Big"
                                    content={page404Title}
                                />
                                <h2
                                    className="h2--Big"
                                    dangerouslySetInnerHTML={{
                                        __html: page404Subtitle,
                                    }}
                                />
                                <HorizontalLine />
                                <Button
                                    children={title}
                                    iconRight="arrow2"
                                    link
                                    href={url}
                                    target={target}
                                />
                            </div>
                            <div
                                className={`${styles.Page404__Image} small-12 medium-7 xlarge-offset-1`}
                            >
                                <GatsbyImage image={page404Image} />
                            </div>
                        </div>
                        <LinearPattern />
                    </div>
                </Layout>
            </>
        )
    } else {
        return null
    }
}

export default NotFoundPage
