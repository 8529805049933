import { useEffect } from "react"
import { isBrowser } from "../../../../utils"
import PropTypes from "prop-types"

const AnalyticsPageError = ({ error }) => {
    if (isBrowser()) {
        window.dataLayer = window.dataLayer ?? []
        window._mtm = window._mtm || []
    }

    useEffect(() => {
        const data = {
            event: "PageError",
            error: error,
        }

        window.dataLayer.push(data)
        window._mtm.push(data)
    }, [error])

    return null
}

AnalyticsPageError.propTypes = {
    error: PropTypes.number.isRequired,
}

export default AnalyticsPageError
